const splashBGs=[
"https://flickster-bg-pics.s3.us-east-2.amazonaws.com/giraffe.jpg",
"https://flickster-bg-pics.s3.us-east-2.amazonaws.com/harvest.jpg",
"https://flickster-bg-pics.s3.us-east-2.amazonaws.com/houses.jpg",
"https://flickster-bg-pics.s3.us-east-2.amazonaws.com/iceberg.jpg",
"https://flickster-bg-pics.s3.us-east-2.amazonaws.com/mountain.jpg",
"https://flickster-bg-pics.s3.us-east-2.amazonaws.com/red-flowers.jpg",
"https://flickster-bg-pics.s3.us-east-2.amazonaws.com/rhinos.jpg",
"https://flickster-bg-pics.s3.us-east-2.amazonaws.com/roses.jpg",
"https://flickster-bg-pics.s3.us-east-2.amazonaws.com/two-cows.jpg",
]


export default splashBGs