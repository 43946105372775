export default function AcctEmailsContent(){
    return(
        <div className="take-up-space">
            <h1>Nothing to see here yet 👷</h1>
            {/* <section className="left-column">

            </section>
            <section className="right-column">

            </section> */}
        </div>

    )
}